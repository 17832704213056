var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page tasks-page"},[_c('v-page-header',{attrs:{"title":_vm.$t('pages.tasks.pageTitle'),"icon":"tasks_title"}}),_c('div',{staticClass:"page__body d-flex"},[_c('div',{staticClass:"page__left"},[_c('v-filter',{attrs:{"type":"tasks"}})],1),_c('div',{staticClass:"page__right"},[(!_vm.isLoading)?_c('v-spinner'):(_vm.dataset.length)?[_c('div',{staticClass:"scroll-horizontal"},[_c('div',{staticClass:"list"},[_c('div',{staticClass:"list__header"},[_c('div',{staticClass:"list__title"},[_vm._v(" "+_vm._s(_vm.$t("pages.tasks.pageTitle"))+" ")]),_c('div',{staticClass:"list__columns"},_vm._l((_vm.$t('pages.tasks.fields')),function(field){return _c('div',{staticClass:"list__column"},[_vm._v(" "+_vm._s(field)+" ")])}),0)]),_vm._l((_vm.dataset),function(task,index){return _c('div',{key:task._id,staticClass:"list__row list__row--shadow list__row--white",class:{
                'list__row--opened':
                  _vm.infoItem._id === task._id || index === _vm.activeIndex,
              }},[_c('v-task',{attrs:{"activeIndex":_vm.activeIndex,"id":_vm.id,"infoItem":_vm.infoItem,"index":index,"task":task},on:{"toggleInfo":_vm.toggleInfo,"getSubTasks":_vm.getSubTasks,"toggleDelete":_vm.toggleDelete}}),(_vm.infoItem._id === task._id)?_c('v-task-info',{attrs:{"task":task}}):_vm._e(),(index === _vm.activeIndex)?_c('div',{staticClass:"list__subtasks sub-tasks"},[_c('div',{staticClass:"sub-tasks__title text--blue"},[_vm._v(" "+_vm._s(_vm.$t("pages.tasks.taskSubTasks"))+" ")]),_vm._l((_vm.sub_tasks),function(sub_task,sIndex){return _c('div',{key:sub_task._id,staticClass:"list__row list__row--white",class:{
                    'list__row--opened':
                      _vm.infoSubItem._id === sub_task._id ||
                      _vm.editedItem._id === sub_task._id,
                  }},[_c('v-sub-task',{attrs:{"id":_vm.id,"index":sIndex,"infoItem":_vm.infoSubItem,"editedItem":_vm.editedItem,"task":sub_task},on:{"toggleSubInfo":_vm.toggleSubInfo,"toggleEdit":_vm.toggleEdit,"toggleDelete":_vm.toggleDelete}}),(_vm.infoSubItem._id === sub_task._id)?_c('v-task-info',{attrs:{"task":sub_task}}):_vm._e(),(_vm.editedItem._id === sub_task._id)?_c('v-edit',{attrs:{"task":sub_task}}):_vm._e()],1)})],2):_vm._e()],1)})],2)]),_c('v-pagination',{attrs:{"count":_vm.count}})]:_c('v-not-found-query')],2)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }